
import { AccountingSettings } from '@/models/Provider';
import { computed, defineComponent } from 'vue';
import Multiselect from '@vueform/multiselect';
import { ExternalAccountingSystem, ToOptions } from '@/models/Enums';

export default defineComponent({
    components: { Multiselect },
    props: {
        settings: {
            type: AccountingSettings,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    async setup(props) {
        const externalSystems = ToOptions(ExternalAccountingSystem, 'external-accounting-system.');
        const setting = computed(() => props.settings);

        function getExternalSystemName(params: ExternalAccountingSystem) {
            return externalSystems.find(x => x.id === params)?.name;
        }

        return {
            externalSystems,
            setting,
            getExternalSystemName
        };
    }
});
