
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        text: {
            type: String
        }
    },
    async setup(props, { emit }) {
        function changeSelectedIndex() {
            emit('changeSelectedIndex');
        }
        return { changeSelectedIndex };
    }
});
