
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        form: {
            type: String,
            default: ''
        }
    },
    async setup(props, { emit }) {
        function edit() {
            emit('edit');
        }
        function back() {
            emit('back');
        }
        return { edit, back };
    }
});
