
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        name: {
            type: String,
            default: ''
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    async setup(props, { emit }) {
        function back() {
            emit('back');
        }
        function edit() {
            emit('edit');
        }
        function save() {
            emit('save');
        }
        return { back, edit, save };
    }
});
