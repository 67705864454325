
import { defineComponent, ref, inject, onMounted } from 'vue';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { api } from '@/services/Api';
import q from 'q';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import BankAccounts from '@/components/Accounting/AccountingInfo/BankAccounts.vue';
import IncomeCards from '@/components/Accounting/AccountingInfo/IncomeCards.vue';
import CashierCards from '@/components/Accounting/AccountingInfo/CashierCards.vue';
import CreditCards from '@/components/Accounting/AccountingInfo/CreditCards.vue';
import Settings from '@/components/Accounting/AccountingInfo/Settings.vue';
import BulkInvoices from '@/components/Accounting/AccountingInfo/BulkInvoices.vue';
import { AccountingInfo, ProviderAccountEntity, ProviderIncomeCard, ProviderCashierCard, ProviderCreditCard } from '@/models/Provider';
import { Emitter } from 'mitt';
import { useRoute } from 'vue-router';

export default defineComponent({
    components: { BankAccounts, IncomeCards, CashierCards, CreditCards, Settings, TabView, TabPanel, BulkInvoices },
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        tab: {
            type: Number,
            required: true
        }
    },
    emits: ['tab-changed'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const emitter: Emitter = inject('emitter') as Emitter;
        const loaded = ref(false);
        const accountingInfo = ref<AccountingInfo>(new AccountingInfo());
        const route = useRoute();

        function isEmpty(o: any) {
            for (const prop of Object.entries(o)) {
                if (prop[1]) {
                    if (prop[1] instanceof Array && !prop[1].length) {
                        continue;
                    }
                    return false;
                }
            }
            return true;
        }
        function removeEmpty() {
            accountingInfo.value.providerAccounts = accountingInfo.value.providerAccounts.filter(x => !isEmpty(x));
            accountingInfo.value.providerCashierCards = accountingInfo.value.providerCashierCards.filter(x => !isEmpty(x));
            accountingInfo.value.providerIncomeCards = accountingInfo.value.providerIncomeCards.filter(x => !isEmpty(x));
        }

        async function save() {
            const response = await api.saveAccountingInfo(accountingInfo.value);
            await q.delay(400);
            swal.close();
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            const url = location.origin + '/provider/accounting/' + props.tab;
            location.replace(url);
        }

        function addBankAccount() {
            if (!props.editMode) {
                router.push({ name: 'AccountingInfoEditView', params: { tab: props.tab, addItem: 'select-bank-account' } });
                return;
            }
            accountingInfo.value.providerAccounts.push(new ProviderAccountEntity());
            emitter.emit('select-bank-account', -1);
        }

        function removeBankAccount(index: number) {
            accountingInfo.value.providerAccounts.splice(index, 1);
        }

        function addIncomeCard() {
            if (!props.editMode) {
                router.push({ name: 'AccountingInfoEditView', params: { tab: props.tab, addItem: 'select-income-card' } });
                return;
            }
            accountingInfo.value.providerIncomeCards.push(new ProviderIncomeCard());
            emitter.emit('select-income-card', -1);
        }

        function removeIncomeCard(index: number) {
            accountingInfo.value.providerIncomeCards.splice(index, 1);
        }

        function addCashierCard() {
            if (!props.editMode) {
                router.push({ name: 'AccountingInfoEditView', params: { tab: props.tab, addItem: 'select-cashier-card' } });
                return;
            }
            accountingInfo.value.providerCashierCards.push(new ProviderCashierCard());
            emitter.emit('select-cashier-card', -1);
        }

        function removeCashierCard(index: number) {
            accountingInfo.value.providerCashierCards.splice(index, 1);
        }

        function onTabChange(e: any) {
            emit('tab-changed', e.index);
        }

        const submitForm = async (event: any) => {
            swal.showLoading();
            removeEmpty();
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                await q.delay(200);
                const el = document.querySelector('.form-controll-multiselect,input:invalid') as HTMLElement;
                emit('tab-changed', el.dataset.tab);
                switch (el.dataset.tab) {
                    case '0': {
                        emitter.emit('select-bank-account', el.dataset.item);
                        break;
                    }
                    case '1': {
                        emitter.emit('select-income-card', el.dataset.item);
                        break;
                    }
                    case '2': {
                        emitter.emit('select-cashier-card', el.dataset.item);
                        break;
                    }
                    case '3': {
                        emitter.emit('select-credit-card', el.dataset.item);
                        break;
                    }
                    case '4': {
                        emitter.emit('settings', el.dataset.item);
                        break;
                    }
                    default: {
                        console.log('Unexpected tab');
                    }
                }
                swal.fire({
                    icon: 'warning',
                    title: t('alert.mandatoryfields'),
                    confirmButtonText: t('button.close')
                });
            } else {
                await save();
            }
        };

        const onMountedCall = async () => {
            swal.showLoading();
            const delay = q.delay(400);
            const response = await api.getAccountingInfo();
            await delay;
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            if (response.data) {
                accountingInfo.value = response?.data;
            }
            swal.close();
            loaded.value = true;
            if (route.params.addItem) {
                await q.delay(50);
                const itemName = route.params.addItem.toString();
                switch (itemName) {
                    case 'select-bank-account':
                        accountingInfo.value.providerAccounts.push(new ProviderAccountEntity());
                        emitter.emit(itemName, -1);
                        break;
                    case 'select-income-card':
                        accountingInfo.value.providerIncomeCards.push(new ProviderIncomeCard());
                        emitter.emit(itemName, -1);
                        break;
                    case 'select-cashier-card':
                        accountingInfo.value.providerCashierCards.push(new ProviderCashierCard());
                        emitter.emit(itemName, -1);
                        break;
                }
            }
        };
        onMounted(onMountedCall);

        return {
            loaded,
            addBankAccount,
            removeBankAccount,
            addIncomeCard,
            removeIncomeCard,
            addCashierCard,
            removeCashierCard,
            accountingInfo,
            onTabChange,
            submitForm
        };
    }
});
