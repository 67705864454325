
import { ProviderCreditCard } from '@/models/Provider';
import { computed, defineComponent, inject, onMounted, onUnmounted, PropType, ref } from 'vue';
import { CardType } from '@/models/Enums';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { Emitter } from 'mitt';
import AccountingMobileCardHeader from '@/components/Accounting/AccountingInfo/AccountingMobileCardHeader.vue';

export default defineComponent({
    components: { AccountingMobileCardHeader },
    props: {
        creditCards: {
            type: [] as PropType<ProviderCreditCard[]>,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    async setup(props) {
        const filterValue = ref('');
        const selectedIndex = ref<number | null>(0);
        const { t } = useI18n();
        const emitter: any = inject('emitter');

        const selectedItem = computed<ProviderCreditCard | null>(() => {
            return selectedIndex.value != null ? props.creditCards[selectedIndex.value] ?? new ProviderCreditCard() : null;
        });

        function getCardTypeName(value: CardType | null) {
            if (!value) return '';
            return t(`cardType.${CardType[value]}`);
        }

        const filteredItems = computed<ProviderCreditCard[]>(() =>
            props.creditCards.filter(x => getCardTypeName(x.cardType).startsWith(filterValue.value))
        );

        function select(index: number) {
            if (index == -1) {
                filterValue.value = '';
                index = filteredItems.value.length - 1;
            }
            const item = filteredItems.value[index];
            selectedIndex.value = props.creditCards.indexOf(item);
        }

        onMounted(() => emitter.on('select-credit-card', (i: number) => select(i)));
        onUnmounted(() => emitter.off('select-credit-card', (i: number) => select(i)));

        function isSelected(index: number) {
            const item = filteredItems.value[index];
            return props.creditCards.indexOf(item) == selectedIndex.value;
        }

        function isEmpty(o: any) {
            for (const prop of Object.entries(o).filter(x => ['id', 'cardType'].every(p => p != x[0]))) {
                if (prop[1]) {
                    if (prop[1] instanceof Array && !prop[1].length) {
                        continue;
                    }
                    return false;
                }
            }
            return true;
        }

        function wrapInner(bottom: number, top: number, setter: (num: string | null) => void, value: string | null) {
            if (bottom == 0) {
                value = '';
            }
            if (value == '' || value == null) {
                setter(null);
            } else {
                const lower = Math.min(31, Math.max(0, bottom));
                const upper = Math.max(0, Math.min(31, top));
                setter(Math.max(Math.min(upper, Number(value)), lower).toString());
            }
        }

        function updateCreditCard() {
            if (selectedItem.value == null) {
                return;
            }
            if (Number(selectedItem.value.firstFromDay) == 0) {
                selectedItem.value.firstToDay = null;
            }
            if (Number(selectedItem.value.firstToDay) == 0) {
                selectedItem.value.secondFromDay = null;
            }
            if (Number(selectedItem.value.secondFromDay) == 0) {
                selectedItem.value.secondToDay = null;
            }
            wrapInner(1, 31, x => (selectedItem.value!.firstFromDay = x), selectedItem.value.firstFromDay);
            wrapInner(
                Number(selectedItem.value.firstFromDay) + 1,
                31,
                x => (selectedItem.value!.firstToDay = x),
                selectedItem.value.firstToDay
            );
            wrapInner(
                Number(selectedItem.value.firstToDay) + 1,
                31,
                x => (selectedItem.value!.secondFromDay = x),
                selectedItem.value.secondFromDay
            );
            wrapInner(
                Number(selectedItem.value.secondFromDay) + 1,
                31,
                x => (selectedItem.value!.secondToDay = x),
                selectedItem.value.secondToDay
            );
        }

        function wrap(bottom: number, top: number, setter: (num: string | null) => void, event: Event) {
            wrapInner(bottom, top, setter, (event.target as HTMLInputElement).value);
            updateCreditCard();
        }

        return {
            wrap,
            selectedItem,
            selectedIndex,
            isSelected,
            select,
            filterValue,
            filteredItems,
            getCardTypeName,
            isEmpty,
            updateCreditCard
        };
    }
});
