
import { ProviderAccountEntity, ProviderCashierCard } from '@/models/Provider';
import { computed, defineComponent, PropType, ref, onMounted, onUnmounted, inject } from 'vue';
import swal from 'sweetalert2';
import { IdName, Currency } from '@/models/Interfaces';
import { generalStore } from '@/store';
import Multiselect from '@vueform/multiselect';
import { CardType, PaymentType, ToOptions } from '@/models/Enums';
import { useI18n } from 'vue-i18n';
import AccountingMobileCardHeader from '@/components/Accounting/AccountingInfo/AccountingMobileCardHeader.vue';

export default defineComponent({
    components: { Multiselect, AccountingMobileCardHeader },
    props: {
        cashierCards: {
            type: [] as PropType<ProviderCashierCard[]>,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    emits: ['add-item', 'remove-item'],
    async setup(props, { emit }) {
        const currencies = ref<Currency[]>([]);
        const filterValue = ref('');
        const selectedIndex = ref<number | null>(0);
        const { t } = useI18n();
        const creditCardPaymentMethod = PaymentType.CreditCard;
        const emitter: any = inject('emitter');

        function isEmpty(o: any) {
            for (const prop of Object.entries(o)) {
                if (prop[1]) {
                    if (prop[1] instanceof Array && !prop[1].length) {
                        continue;
                    }
                    return false;
                }
            }
            return true;
        }

        const paymentTypes = ToOptions(PaymentType, 'payment-type.');
        const cardTypes = ToOptions(CardType, 'card-type.');

        swal.showLoading();
        currencies.value = generalStore.getters.currencies;
        swal.close();

        const selectedItem = computed<ProviderCashierCard | null>(() => {
            return selectedIndex.value != null ? props.cashierCards[selectedIndex.value] : null;
        });

        function updateCardTypes() {
            if (selectedItem.value && selectedItem.value.paymentType.every(x => x != creditCardPaymentMethod)) {
                selectedItem.value.cardTypes = [];
            }
        }

        const filteredItems = computed<ProviderCashierCard[]>(() =>
            props.cashierCards.filter(x => x.cardName.startsWith(filterValue.value))
        );

        function addItem() {
            filterValue.value = '';
            emit('add-item');
        }

        async function remove() {
            const resultSwal = await swal.fire({
                title: t('alert.confirm-remove-card'),
                icon: 'warning',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesDeleteIt'),
                cancelButtonText: t('button.cancel')
            });
            if (!resultSwal.isConfirmed) return;
            const toDelete = selectedIndex.value;
            selectedIndex.value = 0;
            emit('remove-item', toDelete);
        }

        function select(index: number) {
            if (index == -1) {
                filterValue.value = '';
                index = filteredItems.value.length - 1;
            }
            const item = filteredItems.value[index];
            selectedIndex.value = props.cashierCards.indexOf(item);
        }

        onMounted(() => emitter.on('select-cashier-card', (i: number) => select(i)));
        onUnmounted(() => emitter.off('select-cashier-card', (i: number) => select(i)));

        function isSelected(index: number) {
            const item = filteredItems.value[index];
            return props.cashierCards.indexOf(item) == selectedIndex.value;
        }

        function getCurrenciesToShow(id: string) {
            return currencies.value
                .filter(x => x.id == id)
                .map(x => x.symbol)
                .join(', ');
        }

        function getCardTypeNames(value: CardType[]) {
            return value.map(x => t(`card-type.${CardType[x]}`)).join(', ');
        }

        function getPaymentTypeNames(ids: number[]) {
            return ids.map(x => t(`payment-type.${PaymentType[x]}`)).join(', ');
        }

        return {
            addItem,
            remove,
            selectedItem,
            selectedIndex,
            isSelected,
            select,
            filterValue,
            filteredItems,
            currencies,
            getCurrenciesToShow,
            getCardTypeNames,
            creditCardPaymentMethod,
            paymentTypes,
            cardTypes,
            getPaymentTypeNames,
            isEmpty,
            updateCardTypes
        };
    }
});
