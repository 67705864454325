
import { ProviderAccountEntity } from '@/models/Provider';
import { computed, defineComponent, PropType, ref, onMounted, toRef, onUnmounted, inject } from 'vue';
import swal from 'sweetalert2';
import { IdName, Currency } from '@/models/Interfaces';
import { generalStore } from '@/store';
import Multiselect from '@vueform/multiselect';
import InputNumber from 'primevue/inputnumber';
import { useI18n } from 'vue-i18n';
import AccountingMobileCardHeader from '@/components/Accounting/AccountingInfo/AccountingMobileCardHeader.vue';

export default defineComponent({
    components: { Multiselect, AccountingMobileCardHeader },
    props: {
        bankAccounts: {
            type: [] as PropType<ProviderAccountEntity[]>,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    emits: ['add-item', 'remove-item'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const banks = ref<IdName[]>([]);
        const currencies = ref<Currency[]>([]);
        const filterValue = ref('');
        const selectedIndex = ref<number | null>(0);
        const emitter: any = inject('emitter');

        function isEmpty(o: any) {
            for (const prop of Object.entries(o)) {
                if (prop[1]) {
                    if (prop[1] instanceof Array && !prop[1].length) {
                        continue;
                    }
                    return false;
                }
            }
            return true;
        }

        const selectedItem = computed<ProviderAccountEntity | null>(() => {
            return selectedIndex.value != null ? props.bankAccounts[selectedIndex.value] : null;
        });

        const filteredItems = computed<ProviderAccountEntity[]>(() => props.bankAccounts.filter(x => x.name.startsWith(filterValue.value)));

        function addItem() {
            filterValue.value = '';
            emit('add-item');
        }

        async function remove() {
            const resultSwal = await swal.fire({
                title: t('alert.confirm-remove-account'),
                icon: 'warning',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesDeleteIt'),
                cancelButtonText: t('button.cancel')
            });
            if (!resultSwal.isConfirmed) return;
            const toDelete = selectedIndex.value;
            selectedIndex.value = null;
            emit('remove-item', toDelete);
        }

        function select(index: number) {
            if (index == -1) {
                filterValue.value = '';
                index = filteredItems.value.length - 1;
            }
            const item = filteredItems.value[index];
            selectedIndex.value = props.bankAccounts.indexOf(item);
        }

        onMounted(() => emitter.on('select-bank-account', (i: number) => select(i)));
        onUnmounted(() => emitter.off('select-bank-account', (i: number) => select(i)));

        swal.showLoading();
        banks.value = await generalStore.dispatch('getBanks');
        currencies.value = generalStore.getters.currencies;
        swal.close();

        function isSelected(index: number) {
            const item = filteredItems.value[index];
            return props.bankAccounts.indexOf(item) == selectedIndex.value;
        }

        function getCurrenciesToShow(ids: string[]) {
            return currencies.value
                .filter(x => ids.includes(x.id))
                .map(x => x.symbol)
                .join(', ');
        }

        function getBankName(bankId: string | null) {
            if (!bankId) {
                return '';
            }

            return banks.value.find(x => x.id == bankId.toString())?.name ?? '';
        }

        return {
            addItem,
            remove,
            selectedItem,
            selectedIndex,
            isSelected,
            select,
            filterValue,
            filteredItems,
            banks,
            currencies,
            getCurrenciesToShow,
            getBankName,
            isEmpty
        };
    }
});
